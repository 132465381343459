import React from 'react';
import { Divider, } from 'antd';
import './index.less'

function Footer() {
  return(
    <div className="footer">
      <div className="footer-content">
        <div className="footer-contents-product">
          <div className="product-title">惠租云SaaS服务</div>
          <div className="product-content">零售门店</div>
          <div className="product-content">微信私域</div>
          <div className="product-content">手机数码</div>
          <div className="product-content">企业办公</div>
        </div>
        <div className="footer-contents-contact">
          <div className="contact-title">C端服务</div>
          <div className="contact-content">手机租赁</div>
          <div className="contact-content">电脑租赁</div>
          <div className="contact-content">数码租赁</div>
          <div className="contact-content">企业租赁</div>
        </div>
        <div className="footer-code-left">
          <div className="footer-icon"></div>
          <div className="footer-icon-tip">客户顾问</div>
        </div>
        <div className="footer-code-center">
          <div className="footer-icon"></div>
          <div className="footer-icon-tip">微信公众号</div>
        </div>
        <div className="footer-code-right">
          <div className="footer-icon"></div>
          <div className="footer-icon-tip">支付宝生活号</div>
        </div>
        <div className="footer-contents-address">
          {/* <div className="footer-contents-phone">0571—26881669</div> */}
          <div className="footer-contents-company">客服电话：0571—26881669</div>
          <div className="footer-contents-company">人工服务：9:30—18:00</div>
          <div className="footer-contents-company">公司名称：杭州脚本信息技术有限公司</div>
          {/* <div className="footer-contents-company">公司地址：杭州市余杭区良睦路1399号</div> */}
          <div className="footer-contents-company">公司地址：浙江省杭州市余杭区仓前街道文一西路1378号E幢10楼</div>
          <div className="footer-contents-company">商务/渠道及其它合作：business@huizustore.com</div>
        </div>
      </div>
      <Divider className="hr" />
      <div className="footer-footer">
        <div className="footer-left">备案/许可证号：<a href="https://beian.miit.gov.cn" rel="noreferrer" target="_blank">浙ICP备19009847号-4</a></div>
        <div className="footer-center">Copyright © 惠租电信业务经营许可证：浙B2-20190991</div>
        <div className="footer-right">@2024 杭州脚本信息技术有限公司 版权所有</div>
      </div>
    </div>
  )
}

export default Footer;